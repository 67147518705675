import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Collapse from 'react-bootstrap/Collapse';
import moment from 'moment-timezone';

import http from '../../services/httpService';

import Confirmation from '../common/confirmation';
import Icon from '../common/icon';
import Input from '../common/input';
import InputSelect from '../common/inputSelect';
import Page from '../common/page';

import SurgeryBlockModal from './surgeryBlockModal';
import AppointmentCancellationModal from './appointmentCancellationModal';
import SurgeryEstimates from './surgeryEstimates';
import SurgeryEstimateFinderModal from './surgeryEstimateFinderModal';
import SurgeryFinderModal from './surgeryFinderModal';
import SurgeryFillerModal from './surgeryFillerModal';
import SurgeryScheduleModal from './surgeryScheduleModal';

import EstimateConstants from '../../estimateConstants';

function SurgeryDashboard() {
  const locationId = localStorage.getItem('locationId');

  const [doctors, setDoctors] = useState([]);
  const [scheduleColumns, setScheduleColumns] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [surgeries, setSurgeries] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const [selectedSurgery, setSelectedSurgery] = useState(null);
  const [showSurgeryScheduleModal, setShowSurgeryScheduleModal] = useState(false);
  const [showSurgeryFinderModal, setShowSurgeryFinderModal] = useState(false);
  const [showCancelSxConfirmationModal, setShowCancelSxConfirmationModal] = useState(false);
  const [showEstimateFinderModal, setShowEstimateFinderModal] = useState(false);

  const [selectedBlock, setSelectedBlock] = useState(null);
  const [showSurgeryBlockModal, setShowSurgeryBlockModal] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [showSurgeryFillerModal, setShowSurgeryFillerModal] = useState(false);

  const [showTxPlanTodoList, setShowTxPlanTodoList] = useState(false);
  const [showCancellationList, setShowCancellationList] = useState(false);
  const [isExpanded, setIsExpanded] = useState([]);

  const [showStats, setShowStats] = useState(false);
  const [statsFilterDr, setStatsFilterDr] = useState('All');
  const [statsFilterEndDate, setStatsFilterEndDate] = useState(
    moment().add(6, 'weeks').format('YYYY-MM-DD')
  );

  const [searchString, setSearchString] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const getDashboardInfo = async () => {
      try {
        setIsLoading(true);
        const { data } = await http.get(`/surgery/getDashboardInfo?location_id=${locationId}`);
        setBlocks(data.blocks);
        setSurgeries(data.surgeries);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
      setIsLoading(false);
    };
    getDashboardInfo();

    const getSurgeryScheduleColumns = async () => {
      try {
        const response = await http.get(`/schedule/getSurgeryColumns?location_id=${locationId}`);

        const doctors = ['All'];
        const scheduleColumns = [{ value: '', name: 'Select' }];
        for (const column of response.data) {
          doctors.push(column.name);
          scheduleColumns.push({ value: column.id, name: column.name });
        }
        setDoctors(doctors);
        setScheduleColumns(scheduleColumns);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
    };

    getSurgeryScheduleColumns();
  }, [locationId]);

  const handleSearch = async searchString => {
    const searchResults = [];

    for (const s of surgeries) {
      if (
        s.name.toLowerCase().includes(searchString.toLowerCase()) ||
        s.first_name.toLowerCase().includes(searchString.toLowerCase()) ||
        s.last_name.toLowerCase().includes(searchString.toLowerCase()) ||
        s.doctor.toLowerCase().includes(searchString.toLowerCase()) ||
        s.complaint.toLowerCase().includes(searchString.toLowerCase())
      ) {
        searchResults.push(s);
      }
    }

    setSearchString(searchString);
    setSearchResults(searchResults);
  };

  const handleCancelBlock = async () => {
    await http.delete(`/surgery/cancelBlock?block_id=${selectedBlock.id}`);
    navigate(0);
  };

  const stats = () => {
    const filteredSurgeries = surgeries
      .filter(t => {
        if (statsFilterDr !== 'All') return t.doctor === statsFilterDr;
        return t;
      })
      .filter(t => {
        if (statsFilterEndDate) {
          return moment(t.start).tz('America/Denver').format('YYYY-MM-DD') <= statsFilterEndDate;
        }
        return t;
      });
    const filteredBlocks = blocks
      .filter(t => t.id)
      .filter(t => {
        if (statsFilterDr !== 'All') return t.doctor === statsFilterDr;
        return t;
      })
      .filter(t => {
        if (statsFilterEndDate) {
          return moment(t.start).tz('America/Denver').format('YYYY-MM-DD') <= statsFilterEndDate;
        }
        return t;
      });

    const numBookedSurgeries = filteredSurgeries.length;
    const numAvailableSurgeries = filteredBlocks.reduce((acc, t) => {
      //days that are maxed out in some way need their max surgeries to be adjusted to reflect what is currently scheduled
      if (t.statusColor !== 'CornflowerBlue') {
        return acc + Number(t.max_surgeries) - Number(t.remainingSx);
      }
      return acc + Number(t.max_surgeries);
    }, 0);

    const numBookedBlocks = filteredSurgeries.reduce((acc, t) => acc + Number(t.numBlocks), 0);
    const numAvailableBlocks = filteredBlocks.reduce((acc, t) => {
      //days that are maxed out in some way need their blocks to be adjusted to reflect what is currently scheduled
      if (t.statusColor !== 'CornflowerBlue') {
        return acc + Number(t.blocks) - Number(t.remainingBlocks);
      }
      return acc + Number(t.blocks);
    }, 0);

    return (
      <React.Fragment>
        <h3 className="mb-4" onClick={() => setShowStats(!showStats)}>
          {showStats && <Icon name="less" />}
          {!showStats && <Icon name="more" />}
          Stats
        </h3>
        <Collapse in={showStats}>
          <div className="m-4">
            <InputSelect
              name="statsFilterDr"
              value={statsFilterDr}
              label="Doctor"
              optionConfig={doctors}
              rawOptions={true}
              onChange={setStatsFilterDr}
            />
            <Input
              className="me-2"
              type="date"
              name="statsFilterEndDate"
              label="End Date"
              value={statsFilterEndDate}
              onChange={setStatsFilterEndDate}
            />
            <div className="ms-2">
              <div>
                <span className="sura font-18">
                  Surgeries ({Math.round((numBookedSurgeries / numAvailableSurgeries) * 100)}%
                  Scheduled)
                </span>
                <div className="ms-3">
                  <div className="font-18">
                    <span className="sura me-2">Available:</span>
                    <span>{numAvailableSurgeries - numBookedSurgeries}</span>
                  </div>
                  <div className="font-18">
                    <span className="sura me-2">Booked:</span>
                    <span>{numBookedSurgeries}</span>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <span className="sura font-18">
                  Blocks ({Math.round((numBookedBlocks / numAvailableBlocks) * 100)}% Scheduled)
                </span>
                <div className="ms-3">
                  <div className="font-18">
                    <span className="sura me-2">Available:</span>
                    <span>{numAvailableBlocks - numBookedBlocks}</span>
                  </div>
                  <div className="font-18">
                    <span className="sura me-2">Booked:</span>
                    <span>{numBookedBlocks}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Collapse>
      </React.Fragment>
    );
  };

  const getAppointmentString = appointment => {
    return `${moment(appointment.start).tz('America/Denver').format('MM/DD/YYYY')}: ${
      appointment.name
    } (${appointment.first_name} ${appointment.last_name}) - ${appointment.complaint} w/ ${
      appointment.doctor
    }`;
  };

  const txPlanTodoList = (
    <React.Fragment>
      <h3 className="mb-4" onClick={() => setShowTxPlanTodoList(!showTxPlanTodoList)}>
        {showTxPlanTodoList && <Icon name="less" />}
        {!showTxPlanTodoList && <Icon name="more" />}
        Treatment Plan To-Do List
      </h3>
      <Collapse in={showTxPlanTodoList}>
        <div className="my-3 ms-4">
          {surgeries
            .filter(t => !t.estimates.find(e => Number(e.status_id) === EstimateConstants.LOCKED))
            .map((t, i) => (
              <p key={i}>{getAppointmentString(t)}</p>
            ))}
        </div>
      </Collapse>
    </React.Fragment>
  );

  const cancellationList = (
    <React.Fragment>
      <h3 className="mb-4" onClick={() => setShowCancellationList(!showCancellationList)}>
        {showCancellationList && <Icon name="less" />}
        {!showCancellationList && <Icon name="more" />}
        Cancellation List
      </h3>
      <Collapse in={showCancellationList}>
        <div className="my-3 ms-4">
          {surgeries
            .filter(t => t.isCancellationList === 'Y')
            .filter(t =>
              moment(t.start)
                .tz('America/Denver')
                .isAfter(moment().tz('America/Denver').endOf('day'))
            )
            .map((t, i) => (
              <p key={i}>{getAppointmentString(t)}</p>
            ))}
        </div>
      </Collapse>
    </React.Fragment>
  );

  const search = (
    <React.Fragment>
      <Input
        className="mt-2 mb-4"
        name="search"
        label="Search"
        value={searchString}
        onChange={handleSearch}
      />
      {searchString && (
        <div className="my-4 ps-2 py-3 border">
          {searchResults.map((t, i) => (
            <p key={i}>{getAppointmentString(t)}</p>
          ))}
          {searchResults.length === 0 && <p className="sura">No results</p>}
        </div>
      )}
    </React.Fragment>
  );

  const surgery = (s, j) => {
    return (
      <div key={j} className="d-flex justify-content-between">
        <div className="mt-2">
          <div className="d-flex">
            <span
              className="font-16 bolder hover-underline pointer"
              onClick={() => navigate(`/client/${s.client_id}/pet/${s.pet_id}`)}
            >
              {s.name} ({s.first_name} {s.last_name})
            </span>

            <Icon
              name="fa fa-user ms-3 align-self-center h-auto"
              tooltipText={`Created by ${s.created_by_first_name} ${s.created_by_last_name}`}
              className="m-0"
            />
          </div>
          <div className="ms-4">
            <div>{s.complaint}</div>
            {s.notes && <div className="ms-3 gray">{s.notes}</div>}
            <div>Dropoff @ {moment(s.start).tz('America/Denver').format('hh:mm A')}</div>
            <div>Estimates</div>
            <SurgeryEstimates surgery={s} />
            <button
              className="ms-2"
              onClick={() => {
                setSelectedSurgery(s);
                setShowEstimateFinderModal(true);
              }}
            >
              <Icon name="fa fa-link" />
              Attach Estimate
            </button>
            <button
              className="ms-2"
              onClick={() =>
                navigate('/estimate-generator', {
                  state: { surgery: s, redirectPath: '/surgery-dashboard' },
                })
              }
            >
              <Icon name="add" />
              Create Estimate
            </button>
          </div>
        </div>
        <div className="d-flex">
          <button
            onClick={() => {
              setSelectedBlock(null);
              setSelectedSurgery(s);
              setShowSurgeryScheduleModal(true);
            }}
          >
            <Icon name="edit" className="blue m-0 me-2" />
          </button>
          <button
            onClick={() => {
              setSelectedSurgery(s);
              setShowSurgeryFinderModal(true);
            }}
          >
            <Icon name="far fa-clock" tooltipText="Reschedule" className="orange m-0 me-2" />
          </button>
          <button
            onClick={() => {
              setSelectedBlock(null);
              setSelectedSurgery(s);
              setShowCancelSxConfirmationModal(true);
            }}
          >
            <Icon name="fa fa-ban" tooltipText="Cancel" className="error m-0" />
          </button>
        </div>
      </div>
    );
  };

  const blockHeader = (b, i, blockIsScheduled) => {
    return (
      <div className="d-flex justify-content-between">
        <h3
          onClick={() => {
            const updatedIsExpanded = { ...isExpanded };
            updatedIsExpanded[i] = !isExpanded[i];
            setIsExpanded(updatedIsExpanded);
          }}
        >
          {isExpanded[i] && <Icon name="less" />}
          {!isExpanded[i] && <Icon name="more" />}
          <Icon name="fa fa-circle" style={{ color: b.statusColor }} />
          {moment(b.date, 'YYYY-MM-DD').format('MM-DD-YYYY - dddd')} ({b.doctor})
          {blockIsScheduled && Number(b.blocks_urgent) > 0 && (
            <Icon name="fa fa-ambulance" tooltipText="Urgent" className="ms-2" />
          )}
          {blockIsScheduled && b.is_hold === 'Y' && (
            <Icon name="far fa-pause-circle" tooltipText="Hold Date" className="ms-2" />
          )}
        </h3>
        {blockIsScheduled && (
          <div className="d-flex">
            <button
              onClick={() => {
                setSelectedBlock(b);
                setShowSurgeryBlockModal(true);
              }}
            >
              <Icon name="edit" className="blue m-0" />
            </button>
            {b.statusColor === 'CornflowerBlue' && (
              <button
                className="ms-2"
                onClick={() => {
                  setSelectedBlock(b);
                  setShowSurgeryFillerModal(true);
                }}
              >
                <Icon name="fa fa-search" tooltipText="Find a fit" className="orange m-0" />
              </button>
            )}
            <button
              className="ms-2"
              onClick={() => {
                setSelectedBlock(b);
                setShowDeleteConfirmationModal(true);
              }}
            >
              <Icon name="fa fa-ban" tooltipText="Cancel" className="error m-0" />
            </button>
          </div>
        )}
        {!blockIsScheduled && (
          <button
            className="ms-2"
            onClick={() => {
              setSelectedBlock({ date: b.date });
              setShowSurgeryBlockModal(true);
            }}
          >
            <Icon name="add" tooltipText="Create Surgery Block" className="attention m-0" />
          </button>
        )}
      </div>
    );
  };

  const blockStats = b => {
    return (
      <div className="ms-3">
        <div>
          Blocks: {b.blocks - b.remainingBlocks}/{b.blocks}{' '}
          {Number(b.blocks_urgent) > 0 ? `**${b.blocks_urgent} Urgent` : ''}
        </div>
        <div>
          Max Surgeries: {b.max_surgeries - b.remainingSx}/{b.max_surgeries}
        </div>
        <div>
          Max Sterile: {b.max_sterile - b.remainingSterile}/{b.max_sterile}
        </div>
        <div>
          Time: {moment(b.start_time, 'hh:mm').format('hh:mm A')} -{' '}
          {moment(b.end_time, 'hh:mm').format('hh:mm A')}
        </div>
        <div>Notes: {b.notes}</div>
      </div>
    );
  };

  const block = (b, i) => {
    const blockIsScheduled = Boolean(b.id);
    return (
      <div key={i} className="p-3 mb-3 border">
        {blockHeader(b, i, blockIsScheduled)}
        <Collapse in={isExpanded[i]}>
          <div>
            {blockIsScheduled && blockStats(b)}
            <div className="mt-2 ms-3 border-top">
              {blockIsScheduled && b.is_hold !== 'Y' && (
                <button
                  className="my-2 me-5 blue"
                  onClick={() => {
                    setSelectedSurgery(null);
                    setSelectedBlock(b);
                    setShowSurgeryScheduleModal(true);
                  }}
                >
                  <Icon name="calendar" />
                  <span className="hover-underline">Schedule Special Case</span>
                </button>
              )}
              {b.surgeries.map((s, j) => surgery(s, j))}
            </div>
          </div>
        </Collapse>
      </div>
    );
  };

  return (
    <Page selectedTab="surgery-dashboard">
      <div className="background-gray box-shadow p-4">
        <h1>Surgery Dashboard</h1>
        <div className="w-100 mt-4">
          {errorMessage && <p className="error">{errorMessage}</p>}
          {isLoading && <i className="fa fa-circle-notch fa-spin" />}
          {!isLoading && (
            <React.Fragment>
              <button
                className="mb-3 me-5 blue"
                onClick={() => {
                  setSelectedSurgery(null);
                  setShowSurgeryFinderModal(true);
                }}
              >
                <i className="fa fa-search me-2" />
                <span className="hover-underline">Surgery Finder</span>
              </button>
              {txPlanTodoList}
              {cancellationList}
              {stats()}
              {search}
              <div className="d-flex justify-content-between">
                <button
                  className="mb-3 me-5 btn-text-primary "
                  onClick={() => {
                    const indexes = [];
                    blocks.map((t, i) => (indexes[i] = !isExpanded[0]));
                    setIsExpanded(indexes);
                  }}
                >
                  {!isExpanded[0] && <Icon name="add" />}
                  {isExpanded[0] && <Icon name="subtract" />}
                  {!isExpanded[0] ? 'Expand' : 'Collapse'} All
                </button>
                <button
                  className="mb-3 btn-text-primary "
                  onClick={() => {
                    setSelectedBlock(null);
                    setShowSurgeryBlockModal(true);
                  }}
                >
                  <Icon name="add" />
                  Create Surgery Block
                </button>
              </div>
              {blocks.map((b, i) => block(b, i))}
            </React.Fragment>
          )}
        </div>
      </div>
      {showSurgeryFinderModal && (
        <SurgeryFinderModal
          surgery={selectedSurgery}
          scheduledBlocks={blocks}
          scheduleColumns={scheduleColumns}
          handleClose={() => setShowSurgeryFinderModal(false)}
        />
      )}
      {showEstimateFinderModal && (
        <SurgeryEstimateFinderModal
          petId={selectedSurgery.pet_id}
          appointmentId={selectedSurgery.id}
          handleClose={() => setShowEstimateFinderModal(false)}
        />
      )}
      {showSurgeryScheduleModal && (
        <SurgeryScheduleModal
          block={selectedBlock}
          surgery={selectedSurgery}
          scheduleColumns={scheduleColumns}
          handleClose={() => setShowSurgeryScheduleModal(false)}
        />
      )}
      {showCancelSxConfirmationModal && (
        <AppointmentCancellationModal
          appointment={selectedSurgery}
          isSurgery={true}
          handleClose={() => setShowCancelSxConfirmationModal(false)}
        />
      )}
      {showSurgeryFillerModal && (
        <SurgeryFillerModal
          block={selectedBlock}
          surgeries={surgeries}
          scheduleColumns={scheduleColumns}
          handleClose={() => setShowSurgeryFillerModal(false)}
        />
      )}
      {showSurgeryBlockModal && (
        <SurgeryBlockModal
          block={selectedBlock}
          scheduleColumns={scheduleColumns}
          handleClose={() => setShowSurgeryBlockModal(false)}
        />
      )}
      {showDeleteConfirmationModal && (
        <Confirmation
          message="Are you sure you would like to cancel this surgery block?"
          handleConfirm={handleCancelBlock}
          handleClose={() => setShowDeleteConfirmationModal(false)}
        />
      )}
    </Page>
  );
}

export default SurgeryDashboard;
