import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment-timezone';

import http from '../../services/httpService';

import ButtonPanel from '../common/buttonPanel';
import InputSelect from '../common/inputSelect';
import InputTextarea from '../common/inputTextarea';

function AppointmentCancellationModal({ appointment, isSurgery, handleClose }) {
  const [reason, setReason] = useState(1);
  const [notes, setNotes] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (isSurgery) {
      await http.delete(
        `/surgery/cancelSurgery?appointment_id=${appointment.id}&reason=${reason}&notes=${notes}`
      );
    } else {
      await http.delete(
        `/appointment/cancelAppointment?appointment_id=${appointment.id}&reason=${reason}&notes=${notes}`
      );
    }
    navigate(0);
  };

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form>
        <p className="input-width sura font-16 mb-4">
          Are you sure you would like to cancel the appointment for {appointment.name} on{' '}
          {moment(appointment.start).tz('America/Denver').format('MM/DD/YY')} with{' '}
          {appointment.doctor}? This action cannot be undone
        </p>
        <InputSelect
          name="reason"
          value={reason}
          label="Reason"
          optionConfig={[
            { value: 1, name: 'Cancelled by Client' },
            { value: 2, name: 'Cancelled by Clinic' },
            { value: 3, name: 'Created in Error' },
            { value: 4, name: 'No Show' },
          ]}
          onChange={setReason}
        />
        <InputTextarea name="notes" value={notes} label="Notes" rows="2" onChange={setNotes} />
        <ButtonPanel
          primaryButtonText="Confirm"
          secondaryButtonText="Back"
          handleCancel={handleClose}
          handleSubmit={handleSubmit}
          disabled={reason === 1 && !notes}
        />
      </form>
    </Modal>
  );
}

export default AppointmentCancellationModal;
