import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import '../styles/global/react-popper-tooltip.css';
import { usePopperTooltip } from 'react-popper-tooltip';
import { useNavigate } from 'react-router-dom';

import http from '../services/httpService';

import logo from '../assets/billing-logo-short.webp';

import ClockInModal from './employee/clockInModal';
import ClockOutModal from './employee/clockOutModal';

import Account from './account';

import Icon from './common/icon';
import { Mobile, NotMobile } from './common/responsive';

import Constants from '../constants';

import '../styles/components/nav.scss';

function Navbar({ numTasksRealTime, selectedTab }) {
  const navigate = useNavigate();

  const locationId = localStorage.getItem('locationId');
  if (!locationId) {
    localStorage.setItem('locationId', 1);
    navigate(0);
  }

  const [loggedInUser, setLoggedInUser] = useState(JSON.parse(localStorage.getItem('loggedIn')));
  const [numTasksOnLoad, setNumTasksOnLoad] = useState(0);

  const [showUserDetails, setShowUserDetails] = useState(false);
  const [showClockInModal, setShowClockInModal] = useState(false);
  const [showClockOutModal, setShowClockOutModal] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const getTasks = async () => {
      const response = await http.get('/report/getTasks');
      setNumTasksOnLoad(response.data.length);
    };
    getTasks();
  }, []);

  const logout = async () => {
    try {
      await http.post('/logout');
      localStorage.clear();
      setLoggedInUser(undefined);
    } catch (error) {}
  };

  useIdleTimer({
    timeout: 1000 * 60 * 60 * 2,
    onIdle: logout,
    debounce: 500,
  });

  const { getTooltipProps, setTooltipRef, setTriggerRef } = usePopperTooltip({
    offset: [0, 0],
    trigger: 'click',
    onVisibleChange: () => setVisible(!visible),
  });

  const numTasks = typeof numTasksRealTime !== 'undefined' ? numTasksRealTime : numTasksOnLoad;

  const adminTabs = (
    <React.Fragment>
      <Link to="/" className={'nav-tab' + (selectedTab === 'home' ? ' selected' : '')}>
        <Mobile>
          <Icon name="home" />
        </Mobile>
        <span>Clients</span>
      </Link>
      <NotMobile>
        <Link
          to="/schedule"
          className={'nav-tab' + (selectedTab === 'schedule' ? ' selected' : '')}
        >
          <span>Schedule</span>
        </Link>
        <Link
          to="/surgery-dashboard"
          className={'nav-tab' + (selectedTab === 'surgery-dashboard' ? ' selected' : '')}
        >
          <span>Surgery</span>
        </Link>
        <Link
          to="/estimates"
          className={'nav-tab' + (selectedTab === 'estimates' ? ' selected' : '')}
        >
          <span>Estimates</span>
        </Link>
        <Link
          to="/inventory"
          className={'nav-tab' + (selectedTab === 'inventory' ? ' selected' : '')}
        >
          <span>Inventory</span>
        </Link>
      </NotMobile>
      {loggedInUser && Number(loggedInUser.role_id) === Constants.MANAGER && (
        <Link to="/admin" className={'nav-tab' + (selectedTab === 'admin' ? ' selected' : '')}>
          <Mobile>
            <Icon name="admin" />
          </Mobile>
          <span>Admin</span>
        </Link>
      )}
      <Link to="/tasks" className={'nav-tab' + (selectedTab === 'tasks' ? ' selected' : '')}>
        <Mobile>
          <Icon name="bell_alert" />
          <span>Tasks</span>
        </Mobile>
        <NotMobile>
          <div>
            <Icon name="bell_alert" />
            {numTasks !== 0 && <span className="badge">{numTasks}</span>}
          </div>
        </NotMobile>
      </Link>
    </React.Fragment>
  );

  if (!loggedInUser) {
    return <Navigate to="/login" />;
  }

  return (
    <React.Fragment>
      <Mobile>
        <nav>
          <div className="nav-tabs">
            {adminTabs}
            <Link
              to="/account"
              className={'nav-tab' + (selectedTab === 'account' ? ' selected' : '')}
            >
              <Icon name="user" />
              <span>Profile</span>
            </Link>
            <div className="nav-tab" onClick={logout}>
              <Icon name="right_arrow" />
              <span>Logout</span>
            </div>
          </div>
        </nav>
      </Mobile>
      <NotMobile>
        <nav className="container-fluid d-flex flex-row justify-content-center">
          <div className="nav-content flex-row-aligned justify-content-between flex-grow-1">
            <img src={logo} alt="Twin Peaks Veterinary Clinic logo" />
            <div className="nav-tabs">
              {adminTabs}
              <select
                className="form-select"
                id="locationId"
                name="locationId"
                value={locationId}
                onChange={({ target }) => {
                  localStorage.setItem('locationId', target.value);
                  navigate(0);
                }}
              >
                <option key={1} value={1}>
                  Loveland
                </option>
                <option key={2} value={2}>
                  Evans
                </option>
              </select>
              <div className="nav-tab" ref={setTriggerRef}>
                <Icon name="user" />
                <span style={{ width: '120px' }}>
                  {loggedInUser.first_name} {loggedInUser.last_name}
                </span>
                {visible && (
                  <div
                    ref={setTooltipRef}
                    {...getTooltipProps({ className: 'tooltip-container options' })}
                  >
                    <button
                      onClick={() => {
                        setShowUserDetails(true);
                        setVisible(false);
                      }}
                    >
                      Profile
                    </button>
                    {loggedInUser.is_hourly && !loggedInUser.is_clocked_in && (
                      <button
                        onClick={() => {
                          setShowClockInModal(true);
                          setVisible(false);
                        }}
                      >
                        Clock In
                      </button>
                    )}
                    {loggedInUser.is_hourly && loggedInUser.is_clocked_in && (
                      <button
                        onClick={() => {
                          setShowClockOutModal(true);
                          setVisible(false);
                        }}
                      >
                        Clock Out
                      </button>
                    )}
                    {loggedInUser.is_hourly && (
                      <button onClick={() => navigate(`/timesheets/${loggedInUser.id}`)}>
                        Time Sheets
                      </button>
                    )}
                    <button onClick={logout}>Logout</button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </nav>
        {showUserDetails && (
          <Account employeeId={loggedInUser.id} handleClose={() => setShowUserDetails(false)} />
        )}
        {(loggedInUser.prompt_clock_in || showClockInModal) && (
          <ClockInModal
            handleClose={() => {
              if (loggedInUser.prompt_clock_in) {
                const updatedLoggedInUser = { ...loggedInUser };
                updatedLoggedInUser.prompt_clock_in = false;
                localStorage.setItem('loggedIn', JSON.stringify(updatedLoggedInUser));
                setLoggedInUser(updatedLoggedInUser);
              }
              setShowClockInModal(false);
            }}
          />
        )}
        {showClockOutModal && <ClockOutModal handleClose={() => setShowClockOutModal(false)} />}
      </NotMobile>
    </React.Fragment>
  );
}

export default Navbar;
