import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment-timezone';

import http from '../../services/httpService';

import Timepunch from './timepunch';

import Confirmation from '../common/confirmation';
import Icon from '../common/icon';
import Input from '../common/input';
import InputSelect from '../common/inputSelect';
import Page from '../common/page';
import TooltipWrapper from '../common/tooltipWrapper';

import { generateCsv } from '../../util';

function Timesheets() {
  const params = useParams();
  const isEmployeeProfile = Boolean(params.employeeId);

  const [employees, setEmployees] = useState([]);
  const [results, setResults] = useState([]);

  const [employeeId, setEmployeeId] = useState(isEmployeeProfile ? Number(params.employeeId) : '');
  const [startDate, setStartDate] = useState(
    moment().tz('America/Denver').startOf('isoWeek').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState(
    moment().tz('America/Denver').endOf('isoWeek').format('YYYY-MM-DD')
  );

  const [showTimepunchModal, setShowTimepunchModal] = useState(false);
  const [showDeleteTimepunchModal, setShowDeleteTimepunchModal] = useState(false);
  const [selectedTimepunch, setSelectedTimepunch] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const [filterType, setFilterType] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const getEmployees = async () => {
      try {
        setIsLoading(true);
        setErrorMessage('');
        const response = await http.get('/employee/getActive');
        setEmployees([
          { name: 'All', value: '' },
          ...response.data.map(t => {
            return { name: `${t.first_name} ${t.last_name}`, value: t.id };
          }),
        ]);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
      setIsLoading(false);
    };
    if (!isEmployeeProfile) {
      getEmployees();
    }
  }, [isEmployeeProfile]);

  useEffect(() => {
    const getTimepunchReport = async () => {
      try {
        setIsLoading(true);
        setErrorMessage('');
        const response = await http.post(`/report/getTimepunchReport`, {
          startDate,
          endDate,
          employeeId,
        });
        setResults(response.data);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
      setIsLoading(false);
    };
    getTimepunchReport();
  }, [startDate, endDate, employeeId]);

  const handlePreviousWeek = () => {
    setStartDate(
      moment
        .tz(startDate, 'America/Denver')
        .subtract(1, 'week')
        .startOf('isoWeek')
        .format('YYYY-MM-DD')
    );
    setEndDate(
      moment.tz(endDate, 'America/Denver').subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD')
    );
  };

  const handleNextWeek = () => {
    setStartDate(
      moment.tz(startDate, 'America/Denver').add(1, 'week').startOf('isoWeek').format('YYYY-MM-DD')
    );
    setEndDate(
      moment.tz(endDate, 'America/Denver').add(1, 'week').endOf('isoWeek').format('YYYY-MM-DD')
    );
  };

  const handleDownloadReport = () => {
    const timpunchReportItems = [];

    for (const item of results) {
      for (const punch of item.timepunches) {
        timpunchReportItems.push(punch);
      }
    }

    generateCsv(`timepunches_${startDate}_${endDate}`, timpunchReportItems);
  };

  const handleDeleteTimepunch = async () => {
    await http.delete(`/employee/deleteTimepunch?id=${selectedTimepunch.id}`);
    navigate(0);
  };

  const isWeek =
    moment.tz(startDate, 'America/Denver').day() === 1 &&
    moment.tz(endDate, 'America/Denver').day() === 0 &&
    moment.tz(endDate, 'America/Denver').diff(moment.tz(startDate, 'America/Denver'), 'day') === 6;

  const aggregateStats = (
    <React.Fragment>
      <h2 className="my-3">
        Total Hours:{' '}
        {results
          .map(t => Number(t.hours_total))
          .reduce((a, b) => a + b, 0)
          .toFixed(2)}
      </h2>
      <div className="ms-3 mb-3">
        <h3 className="mb-1">
          Loveland:{' '}
          {results
            .map(t => Number(t.hours_loveland))
            .reduce((a, b) => a + b, 0)
            .toFixed(2)}
        </h3>
        <h3 className="mb-1">
          Evans:{' '}
          {results
            .map(t => Number(t.hours_evans))
            .reduce((a, b) => a + b, 0)
            .toFixed(2)}
        </h3>
        {isWeek && (
          <h3 className="mb-1">
            Overtime:{' '}
            {results
              .map(t => Number(t.hours_overtime))
              .reduce((a, b) => a + b, 0)
              .toFixed(2)}
          </h3>
        )}
      </div>
    </React.Fragment>
  );

  return (
    <Page>
      <div className="p-4 background-gray box-shadow d-flex flex-column h-100">
        {errorMessage && <p className="error">{errorMessage}</p>}
        <h1 className="mb-4">Time Sheets</h1>
        {!isEmployeeProfile && (
          <InputSelect
            name="employeeId"
            value={employeeId}
            label="Employee"
            optionConfig={employees}
            onChange={setEmployeeId}
          />
        )}
        <div className="d-flex my-2">
          <Input
            className="me-2"
            type="date"
            name="startDate"
            label="Start Date"
            value={startDate}
            onChange={setStartDate}
          />
          <Input
            type="date"
            name="endDate"
            label="End Date"
            value={endDate}
            onChange={setEndDate}
          />
          <button className="mt-2 ms-2 btn-rounded-primary" onClick={handlePreviousWeek}>
            Previous Week
          </button>
          <button className="mt-2 ms-2 btn-rounded-primary" onClick={handleNextWeek}>
            Next Week
          </button>
        </div>
        {!isEmployeeProfile && (
          <InputSelect
            name="filterType"
            value={filterType}
            label="Filter Type"
            optionConfig={['None', 'Missing Punches', 'Edited Punches']}
            rawOptions={true}
            onChange={value => setFilterType(value === 'None' ? '' : value)}
          />
        )}
        {!isEmployeeProfile && aggregateStats}
        {isLoading && (
          <i className="fa fa-circle-notch fa-spin fa-2x flex-centered flex-grow-1 p-5 subtle" />
        )}
        {!isLoading && (
          <React.Fragment>
            {results.length > 0 && (
              <div className="mt-3 p-2">
                {!isEmployeeProfile && (
                  <div className="mb-5">
                    <button
                      className="mb-3 btn-text-primary"
                      onClick={() => {
                        setSelectedTimepunch(null);
                        setSelectedEmployee(null);
                        setShowTimepunchModal(true);
                      }}
                    >
                      <Icon name="add" className="m-0 me-2" />
                      New Timepunch
                    </button>
                    {!filterType && (
                      <button className="btn-text-primary" onClick={handleDownloadReport}>
                        <Icon name="download" />
                        Download Report
                      </button>
                    )}
                  </div>
                )}
                {results.map((t, i) => {
                  return (
                    <div className="mb-5" key={i}>
                      <div className="mb-4 d-flex justify-content-between">
                        <h2>{t.employee_name}</h2>
                        <button
                          className="btn-text-primary"
                          onClick={() => {
                            setSelectedTimepunch(null);
                            setSelectedEmployee(t);
                            setShowTimepunchModal(true);
                          }}
                        >
                          <Icon name="add" className="m-0 me-2" />
                          New Timepunch
                        </button>
                      </div>
                      <table>
                        <thead>
                          <tr className="border-bottom">
                            <th>Clock In</th>
                            <th>Clock Out</th>
                            <th>Duration</th>
                            <th>Location</th>
                            <th>Clock In Notes</th>
                            <th>Clock Out Notes</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {t.timepunches.map((s, j) => {
                            if (filterType) {
                              if (
                                filterType === 'Missing Punches' &&
                                s.clock_in_ts &&
                                s.clock_out_ts
                              ) {
                                return null;
                              }

                              if (
                                filterType === 'Edited Punches' &&
                                s.clock_in_is_override !== 'Y' &&
                                s.clock_out_is_override !== 'Y'
                              ) {
                                return null;
                              }
                            }

                            return (
                              <tr key={j}>
                                {s.clock_in_is_override !== 'Y' && (
                                  <td>
                                    {moment(s.clock_in_ts)
                                      .tz('America/Denver')
                                      .format('MM/DD/YY hh:mm A')}
                                  </td>
                                )}
                                {s.clock_in_is_override === 'Y' && (
                                  <TooltipWrapper
                                    tooltipText={
                                      <React.Fragment>
                                        Updated by <nobr>{s.clocked_in_by}</nobr> at{' '}
                                        <nobr>
                                          {moment
                                            .tz(s.clock_in_last_updated_ts, 'America/Denver')
                                            .format('MM/DD/YY hh:mm A')}
                                        </nobr>
                                      </React.Fragment>
                                    }
                                    element={
                                      <td className="orange pointer">
                                        {moment(s.clock_in_ts)
                                          .tz('America/Denver')
                                          .format('MM/DD/YY hh:mm A')}
                                      </td>
                                    }
                                  />
                                )}
                                {s.clock_out_is_override !== 'Y' && (
                                  <td>
                                    {s.clock_out_ts ? (
                                      moment(s.clock_out_ts)
                                        .tz('America/Denver')
                                        .format('MM/DD/YY hh:mm A')
                                    ) : (
                                      <span className="error">Missing Punch</span>
                                    )}
                                  </td>
                                )}
                                {s.clock_out_is_override === 'Y' && (
                                  <TooltipWrapper
                                    tooltipText={
                                      <React.Fragment>
                                        Updated by <nobr>{s.clocked_out_by}</nobr> at{' '}
                                        <nobr>
                                          {moment
                                            .tz(s.clock_out_last_updated_ts, 'America/Denver')
                                            .format('MM/DD/YY hh:mm A')}
                                        </nobr>
                                      </React.Fragment>
                                    }
                                    element={
                                      <td className="orange pointer">
                                        {moment(s.clock_out_ts)
                                          .tz('America/Denver')
                                          .format('MM/DD/YY hh:mm A')}
                                      </td>
                                    }
                                  />
                                )}
                                <td>{s.duration}</td>
                                <td>{s.location}</td>
                                <td style={{ width: '210px' }}>{s.clock_in_notes}</td>
                                <td style={{ width: '210px' }}>{s.clock_out_notes}</td>
                                <td>
                                  <div className="d-flex">
                                    <button
                                      onClick={() => {
                                        setSelectedTimepunch(s);
                                        setSelectedEmployee(t);
                                        setShowTimepunchModal(true);
                                      }}
                                    >
                                      <Icon name="edit" className="blue" />
                                    </button>
                                    <button
                                      className="ms-1"
                                      onClick={() => {
                                        setSelectedTimepunch(s);
                                        setShowDeleteTimepunchModal(true);
                                      }}
                                    >
                                      <Icon name="delete" className="error m-0" />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      {!filterType && (
                        <div className="mt-3 d-flex">
                          <div>
                            <div className="mb-1 d-flex align-items-center">
                              <p className="roboto">Loveland Hours:</p>
                              <p className="ms-3">{t.hours_loveland}</p>
                            </div>
                            <div className="mb-1 d-flex align-items-center">
                              <p className="roboto">Evans Hours:</p>
                              <p className="ms-3">{t.hours_evans}</p>
                            </div>
                            <div className="mb-1 d-flex align-items-center">
                              <p className="roboto">Total Hours:</p>
                              <p className="ms-3">{t.hours_total}</p>
                            </div>
                          </div>
                          {isWeek && (
                            <div className="ms-5">
                              {!isEmployeeProfile && (
                                <div className="mb-1 d-flex align-items-center">
                                  <p className="roboto">Set Hours:</p>
                                  <p className="ms-3">{t.employee_set_hours}</p>
                                </div>
                              )}
                              {!isEmployeeProfile && (
                                <div className="mb-1 d-flex align-items-center">
                                  <p className="roboto">Over/Under Set Hours:</p>
                                  <p className="ms-3">{t.hours_diff_from_set}</p>
                                </div>
                              )}
                              <div className="mb-1 d-flex align-items-center">
                                <p className="roboto">Overtime Hours:</p>
                                <p
                                  className={`ms-3 mb-0 ${
                                    Number(t.hours_overtime) > 0 ? 'error' : ''
                                  }`}
                                >
                                  {t.hours_overtime}
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </React.Fragment>
        )}
      </div>
      {showTimepunchModal && (
        <Timepunch
          timepunch={selectedTimepunch}
          selectedEmployee={selectedEmployee}
          handleClose={() => setShowTimepunchModal(false)}
        />
      )}
      {showDeleteTimepunchModal && (
        <Confirmation
          message="Are you sure you want to delete this timepunch?"
          handleConfirm={handleDeleteTimepunch}
          handleClose={() => setShowDeleteTimepunchModal(false)}
        />
      )}
    </Page>
  );
}

export default Timesheets;
